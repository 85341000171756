<template>
  <Menu ref="leftMenu" :open-names="openNames" @on-select="selectMenuNav" :active-name="activeName">
    <sub-menu :subMenuDate="menuData"></sub-menu>
  </Menu>
</template>

<script>
import Mixin from '@/components/mixin/common_mixin';
import subMenu from '@/components/layout/menu/subMenu';

export default {
  name: 'headerMenu',
  mixins: [Mixin],
  components: {
    subMenu: subMenu
  },
  data() {
    return {};
  },
  props: {
    menuData: {
      required: true,
      type: Array
    },
    openNames: {
      type: Array,
      default: () => []
    },
    activeName: {
      type: String | Number
    }
  },
  methods: {
    selectMenuNav(name) {
      localStorage.setItem('activeName', name);
    },
    // 路由变化的时候，匹配选中指定的菜单
    updateMenu(data) {
      let v = this;
      let menuData = [];
      if (v.menuData.length > 0) {
        function handerMenu(menu) {
          menu.forEach((item) => {
            if (item.children && item.children.length > 0) {
              handerMenu(item.children);
            } else {
              menuData.push(item);
            }
          });
        }

        handerMenu(v.menuData);
        if (menuData) {
          menuData.map((item) => {
            if (item.path === data.path) {
              v.updateMenuDom(item);
            } else {
              let meta = data.meta;
              if (Object.keys(meta).length > 0) {
                if (item.path === meta.parentPath) {
                  v.updateMenuDom(item);
                }
              }
            }
          });
        }
      }
    },
    // 更新菜单栏的高亮dom
    updateMenuDom(item) {
      let v = this;
      v.$emit('updateActiveName', item.id);
      localStorage.setItem('activeName', item.id);
      v.$nextTick(function () {
        if (v.$refs.leftMenu) {
          v.$refs.leftMenu.updateOpened();
          v.$refs.leftMenu.updateActiveName();
        }
      });
    }
  },
  watch: {
    $route: {
      handler(data) {
        let v = this;
        v.updateMenu(data);
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>

</style>
