<template>
  <!-- 面包屑 -->
  <div class="layout-breadcrumb" v-if="!hideBreadcrumb">
    <Breadcrumb>
      <BreadcrumbItem v-for="item in BreadMenuData"
        :to="item.path ? item.path.includes('/details') ? null: item.path : null">
        {{ item.name }}
      </BreadcrumbItem>
    </Breadcrumb>
    <div class="flex align-items-center">
      <span class="cursor themeColor ml10 mr10 font-size-13" v-for="item in operatingInstructionsList" @click="previewGuideBtn(item)">
        {{ item.title }}
      </span>
    </div>
  </div>

</template>

<script>
import Mixin from '@/components/mixin/common_mixin';
import ymsMenu from '@/config/menu/yms';
import supplierMenu from "@/config/menu/supplier";
import distributorMenu from "@/config/menu/distributor";
import api from "@/api";

export default {
  name: 'breadcrumb',
  mixins: [Mixin],
  data() {
    return {
      BreadMenuData: [],
      BreadMenuAllData: [],
      menuData: [],
      hideBreadcrumb: false,
      topNavActiveName: localStorage.getItem('topNavActiveName') || 'operationManage', // 默认选中的顶部导航菜单
      operatingInstructionsList: [],
    };
  },
  created() {
    this.initFun(this.topNavActiveName);
  },
  methods: {
    // 初始化接口数据
    initFun(name) {
      this.setMenuData(name);
      this.setBreadMenuData();
      this.getBreadMenuData();
    },
    setMenuData(name) {
      let pathname = window.location.pathname;
      this.topNavActiveName = name;
      let pathnameList = [
        {pathname: '/yms.html', menu: JSON.parse(JSON.stringify(ymsMenu))},
        {pathname: '/supplier.html', menu: JSON.parse(JSON.stringify(supplierMenu[name]))},
        {pathname: '/distributor.html', menu: JSON.parse(JSON.stringify(distributorMenu))}
      ];
      pathnameList.map((item) => {
        if (pathname.includes(item.pathname)) {
          this.menuData = item.menu;
        }
      });
    },
    setBreadMenuData() {
      /**
       * @Description:设置面包屑
       * @author gan
       * @date: 2020/12/18 16:00
       */
      let v = this;
      if (!v.menuData) return [];

      function setId(data, breadIndex) {
        data.forEach((i) => {
          if (!i.breadMenu) i.breadMenu = [];
          i.breadMenu[i.breadIndex] = {
            name: i.name,
            path: i.path,
            hideCrumbs: i.hideCrumbs || false
          };
          if (!i.path) i.path = null;
          if (i.children && i.children.filter(i => i.menuHide).length === i.children.length) {
            v.BreadMenuAllData.push(i);
          }
          if (i.children && i.children.length > 0) {
            i.children.forEach(j => {
              j.breadMenu = JSON.parse(JSON.stringify(i.breadMenu));
              j.breadIndex = i.breadIndex + 1;
            });
            setId(i.children, breadIndex);
          } else {
            v.BreadMenuAllData.push(i);
          }
        });
      }

      let newData = window.location.pathname.includes('/supplier.html') ? v.menuData : v.menuData.menu;
      setId(newData.map(i => {
        return {
          ...i,
          breadIndex: 0
        };
      }), 0);
    },
    getBreadMenuData() {
      this.BreadMenuData = [];
      if (this.BreadMenuAllData) {
        this.BreadMenuAllData.map(i => {
          if (i.path === this.$route.path) {
            if (i.breadMenu) {
              this.BreadMenuData = i.breadMenu;
            }
          }
        });
      }
    },
    // 获取对应系统的操作指引数据
    getSystemOperationGuidelinesData(list) {
      let v = this;
      let newList = [];
      v.operatingInstructionsList = [];
      let systemType = null; // 1分销商系统  2运营系统  3供应商系统
      if (list && list.length > 0) {
        switch (v.system) {
          case 'yms':
            systemType = 2;
            break;
          case 'supplier':
            systemType = 3;
            break;
          case 'distributor':
            systemType = 1;
            break;
        }
        if ([3].includes(systemType)) {
          newList = list.filter((item) => {
            return item.path && item.name
          });
          if (newList.length > 0) {
            let path = v.$route.path;
            newList.map((ele) => {
              if (ele.path === path) {
                let query = {
                  moduleName: ele.name,
                  systemType: systemType
                }
                handleData(query)
              }
            })
          }
        }
      }

      function handleData(query) {
        v.axios.post(api.post_ymsOperatingInstructions_api_queryListByParam, query).then((res) => {
          if (res.data.code === 0) {
            let data = res.data.datas || [];
            if (data.length > 0) {
              // 默认指取前面三条数据展示
              v.operatingInstructionsList = data.slice(0, 3);
            }
          }
        });
      }
    },
    // 预览指引内容
    previewGuideBtn(item) {
      let v = this;
      const {href} = v.$router.resolve({
        path: '/viewGuideContent',
        query: {
          ymsOperatingInstructionsId: item.ymsOperatingInstructionsId
        }
      });
      window.open(href, '_blank');
    }
  },
  watch: {
    '$route': {
      handler: 'getBreadMenuData',
      deep: true
    },
    BreadMenuData: {
      handler(data) {
        this.hideBreadcrumb = false;
        if (data && data.length > 0) {
          this.getSystemOperationGuidelinesData(data);
          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if (item.hideCrumbs) {
              this.hideBreadcrumb = true;
              return false;
            }
          }
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="less" scoped>

.layout-breadcrumb {
  background-color: #fff;
  font-size: 15px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
