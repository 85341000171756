
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';
export default {
  // 运营管理
  operationManage: [
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005264'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'supplier_statistics',
      hideCrumbs: true, // 隐藏面包屑
      guidanceModule: false, // 指引模块（默认开启）  true：开启指引模块    false  不开启指引模块
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006739'),
      icon: '&#xe668;',
      type: 'supplierOrder',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006740'),
          path: '/supplierOrderList',
          menuKey: 'supplier_supplierOrderList',
          guidanceModule: true
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004484'),
          path: '/stockList',
          menuKey: 'supplier_stockList',
          guidanceModule: true,
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005512'),
              path: '/initiateStocking',
              menuKey: 'supplier_initiateStocking',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006741'),
              path: '/stockOrderDetails',
              menuKey: 'supplier_stockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006742'),
          path: '/shipmentRecord',
          menuKey: 'supplier_shipmentRecord',
          guidanceModule: true,
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006743'),
              path: '/shipmentRecordDetails',
              menuKey: 'supplier_shipmentRecordDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005488'),
              path: '/additionalGoods',
              menuKey: 'supplier_additionalGoods',
              menuHide: true // 菜单不展示
            }
          ]
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006744'),
      icon: '&#xe678;',
      type: 'productCenter',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005586'),
          path: '/productCenter/productGoods',
          menuKey: 'supplier_productCenter_productGoods',
          guidanceModule: true,
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002582'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006745'),
              path: '/productDetails',
              menuKey: 'supplier_productDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005633'),
              path: '/collectProducts',
              menuKey: 'yms_collectProducts',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006746'),
          path: '/productCenter/productBrand',
          menuKey: 'supplier_productCenter_productBrand',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006747'),
      icon: '&#xe68c;',
      type: 'platformAuthorization',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006748'),
          path: '/platformAuthorization',
          menuKey: 'supplier_platformAuthorization',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006749'),
      path: '/reconciliationManage',
      menuKey: 'supplier_reconciliationManage',
      icon: '&#xe665;',
      type: 'reconciliationManage',
      guidanceModule: true,
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003434'),
          path: '/reconciliationBillDetails',
          menuKey: 'supplier_reconciliationBillDetails',
          menuHide: true // 菜单不展示
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000285'),
      path: '/messageCenter',
      menuKey: 'supplier_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      icon: '&#xe8be;',
      type: 'messageCenter',
      guidanceModule: true,
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006734'),
          path: '/messageMailboxes',
          menuKey: 'supplier_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006750'),
      icon: '&#xe6e3;',
      type: 'accountManage',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000604'),
          path: '/accountManage/basicInformation',
          menuKey: 'supplier_accountManage_basicInformation',
          guidanceModule: true,
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006735'),
          path: '/accountManage/management',
          menuKey: 'supplier_accountManage_management',
          guidanceModule: true,
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006751'),
          path: '/accountManage/collectionAccount',
          menuKey: 'supplier_accountManage_collectionAccount',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006736'),
      icon: '&#xe664;',
      type: 'importExportTask',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006737'),
          path: '/importTask',
          menuKey: 'supplier_importTask'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006738'),
          path: '/exportTask',
          menuKey: 'supplier_exportTask'
        }
      ]
    }
  ],
  // 仓储管理
  warehouseManage: [
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002525'),
      path: '/wms/searchPackage',
      menuKey: 'supplier_wms_searchPackage',
      icon: '&#xe665;',
      type: 'searchPackage',
      guidanceModule: true,
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006752'),
          path: '/wms/searchPackageDetails',
          menuKey: 'supplier_wms_searchPackageDetails',
          menuHide: true // 菜单不展示
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005961'),
          path: '/addManualSupplierOrder',
          menuKey: 'supplier_addManualSupplierOrder',
          menuHide: true // 菜单不展示
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006753'),
      path: '/wms/packingManage',
      menuKey: 'supplier_wms_packingManage',
      icon: '&#xe61b;',
      type: 'packingManage',
      guidanceModule: true,
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000547'),
          path: '/wms/exwarehouse',
          menuKey: 'supplier_wms_exwarehouse',
          menuHide: true, // 菜单不展示
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005815'),
              path: '/wms/scanEx',
              menuKey: 'supplier_wms_scanEx',
              menuHide: true // 菜单不展示
            }
          ]
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002146'),
      path: '/wms/inventoryLevels',
      menuKey: 'supplier_wms_inventoryLevels',
      icon: '&#xe65f;',
      type: 'inventoryLevels',
      guidanceModule: true,
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006754'),
      icon: '&#xe63c;',
      type: 'orderPicking',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002490'),
          guidanceModule: true,
          path: '/wms/generateOrderList',
          menuKey: 'supplier_wms_generateOrderList'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006691'),
          path: '/wms/generateOrderDetails',
          menuKey: 'supplier_wms_generateOrderDetails',
          menuHide: true // 菜单不展示
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006692'),
          guidanceModule: true,
          path: '/wms/pickList',
          menuKey: 'supplier_wms_pickList'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002455'),
          path: '/wms/pickListDetails',
          menuKey: 'supplier_wms_pickListDetails',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006755'),
      icon: '&#xe630;',
      type: 'outboundPperation',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006756'),
          path: '/wms/sortOrderSetting',
          menuKey: 'supplier_wms_sortOrderSetting',
          guidanceModule: true,
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001888'),
          path: '/wms/sorting',
          menuKey: 'supplier_wms_sorting',
          guidanceModule: true,
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005943'),
          path: '/wms/packWorking',
          menuKey: 'supplier_wms_packWorking',
          guidanceModule: true,
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006736'),
      icon: '&#xe664;',
      type: 'importExportTask',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006737'),
          path: '/importTask',
          menuKey: 'supplier_importTask'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006738'),
          path: '/exportTask',
          menuKey: 'supplier_exportTask'
        }
      ]
    }
  ],
};
