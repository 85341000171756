
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 菜单
 * @author gan
 * @date: 2020/12/17 11:09
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005264'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'yms_statistics',
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005586'),
      icon: '&#xe749;',
      type: 'productList',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002144'),
          path: '/productList',
          menuKey: 'yms_productList',
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002582'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006679'),
              path: '/productDetails',
              menuKey: 'yms_productDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002731'),
          path: '/category',
          menuKey: 'yms_category',
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006680'),
          path: '/sizeTemplateManage',
          menuKey: 'yms_sizeTemplateManage',
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006681'),
              path: '/updateSizeTemplate',
              menuKey: 'yms_updateSizeTemplate',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006682'),
          path: '/approvalList',
          menuKey: 'yms_approvalList',
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006683'),
              path: '/approvalDetails',
              menuKey: 'yms_approvalDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002585'),
              path: '/findSimilarProducts',
              menuKey: 'yms_findSimilarProducts',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002155'),
          path: '/itemAttributes',
          menuKey: 'yms_itemAttributes'
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001099'),
      icon: '&#xe665;',
      type: 'orderList',
      children: [
        {
          name: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001384')}`,
          path: '/orderList',
          menuKey: 'yms_orderList',
          children: [
            {
              name: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004923')}`,
              path: '/orderListDetails',
              menuKey: 'yms_orderListDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006684'),
              path: '/supplierOrderDetails',
              menuKey: 'yms_supplierOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006685'),
          path: '/distributorStockList',
          menuKey: 'yms_distributorStockList'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006686'),
          path: '/supplierStockList',
          menuKey: 'yms_supplierStockList',
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006687'),
              path: '/supplierStockOrderDetails',
              menuKey: 'yms_supplierStockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002154'),
          path: '/supplierOrders',
          menuKey: 'yms_supplierOrders'
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000018'),
      icon: '&#xe679;',
      type: 'wms',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002146'),
          path: '/wms/inventoryLevels',
          menuKey: 'yms_wms_inventoryLevels'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006688'),
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006689'),
              path: '/billLadingManage',
              menuKey: 'yms_billLadingManage',
              children: [
                {
                  name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006690'),
                  path: '/billLadingManageDetails',
                  menuKey: 'yms_billLadingManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001883'),
              path: '/billWarehousing',
              menuKey: 'yms_billWarehousing'
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001884'),
              path: '/commoditySorting',
              menuKey: 'yms_commoditySorting'
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002145'),
              path: '/commoditySticker',
              menuKey: 'yms_commoditySticker'
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001887'),
              path: '/commodityShelves',
              menuKey: 'yms_commodityShelves'
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005779'),
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002490'),
              path: '/assembleGeneratePickingList',
              menuKey: 'yms_assembleGeneratePickingList'
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006691'),
              path: '/assembleGeneratePickingDetails',
              menuKey: 'yms_assembleGeneratePickingDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006684'),
              path: '/assembleSupplierOrderDetails',
              menuKey: 'yms_assembleSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006692'),
              path: '/assemblePickingList',
              menuKey: 'yms_assemblePickingList'
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002455'),
              path: '/assemblePickingListDetails',
              menuKey: 'yms_assemblePickingListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001285'),
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002490'),
              path: '/wms/generateOrderList',
              menuKey: 'yms_wms_generateOrderList'
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006691'),
              path: '/generateOrderDetails',
              menuKey: 'yms_generateOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006684'),
              path: '/generateSupplierOrderDetails',
              menuKey: 'yms_generateSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006692'),
              path: '/wms/pickList',
              menuKey: 'yms_wms_pickList'
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002455'),
              path: '/wms/pickListDetails',
              menuKey: 'yms_wms_pickListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005879'),
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006693'),
              path: '/wms/sortOrderSetting',
              menuKey: 'yms_wms_sortOrderSetting'
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001888'),
              path: '/wms/sorting',
              menuKey: 'yms_wms_sorting'
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001890'),
              path: '/wms/packWorking',
              menuKey: 'yms_wms_packWorking'
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001891'),
              path: '/sortingOut',
              menuKey: 'yms_sortingOut'
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006694'),
              path: '/handoverSheetManage',
              menuKey: 'yms_handoverSheetManage',
              children: [
                {
                  name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006695'),
                  path: '/handoverSheetManageDetails',
                  menuKey: 'yms_handoverSheetManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006696'),
      icon: '&#xe668;',
      type: 'supplierList',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006697'),
          path: '/supplierList',
          menuKey: 'yms_supplierList'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006698'),
          path: '/supplierAuditList',
          menuKey: 'yms_supplierAuditList'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006699'),
          path: '/supplierLevel',
          menuKey: 'yms_supplierLevel'
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006700'),
      icon: '&#xe699;',
      type: 'distributorList',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006701'),
          path: '/customerList',
          menuKey: 'yms_customerList',
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002393'),
          path: '/customerLabel',
          menuKey: 'yms_customerLabel',
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002392'),
          path: '/customerGroups',
          menuKey: 'yms_customerGroups',
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002394'),
          path: '/customerGrade',
          menuKey: 'yms_customerGrade'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006702'),
          path: '/tuokeLink',
          menuKey: 'yms_tuokeLink'
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001100'),
      icon: '&#xe668;',
      type: 'supplierBillManage',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006703'),
          path: '/supplierBillManage',
          menuKey: 'yms_supplierBillManage',
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006704'),
              path: '/supplierBillDetails',
              menuKey: 'yms_supplierBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002156'),
          path: '/customerBill',
          menuKey: 'yms_customerBill',
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006705'),
              path: '/customerBillDetails',
              menuKey: 'yms_customerBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002157'),
          path: '/logisticsBill',
          menuKey: 'yms_logisticsBill',
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006706'),
              path: '/logisticsBillDetails',
              menuKey: 'yms_logisticsBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002158'),
          path: '/warehouseBill',
          menuKey: 'yms_warehouseBill',
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006707'),
              path: '/warehouseBillDetails',
              menuKey: 'yms_warehouseBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002791'),
          path: '/financialJournal',
          menuKey: 'yms_financialJournal'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002151'),
          path: '/rechargeRecord',
          menuKey: 'yms_rechargeRecord'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006708'),
          path: '/passwordManage',
          menuKey: 'yms_passwordManage'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002150'),
          path: '/subsidyReview',
          menuKey: 'yms_subsidyReview'
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006709'),
      icon: '&#xe68e;',
      type: 'logisticsManage',
      children: [
        {
          name: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002961')}`,
          path: '/ymsLogistics',
          menuKey: 'yms_ymsLogistics'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006710'),
          path: '/basicLogistics',
          menuKey: 'yms_basicLogistics'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006711'),
          path: '/smartLogisticsRule',
          menuKey: 'yms_smartLogisticsRule'
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001115'),
      icon: '&#xe68f;',
      type: 'warehouseManage',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006712'),
          path: '/warehouseManage',
          menuKey: 'yms_warehouseManage'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006713'),
          path: '/reservoirManage',
          menuKey: 'yms_reservoirManage'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006714'),
          path: '/locationManage',
          menuKey: 'yms_locationManage'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006715'),
          path: '/messageNotification',
          menuKey: 'yms_messageNotification'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001623'),
          path: '/exchangeRateConfig',
          menuKey: 'yms_exchangeRateConfig'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006716'),
          path: '/containerManage',
          menuKey: 'yms_containerManage'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006717'),
          path: '/containerSettings',
          menuKey: 'yms_containerSettings'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006718'),
          path: '/addressLibraryManage',
          menuKey: 'yms_addressLibraryManage'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006719'),
          path: '/processConfiguration',
          menuKey: 'yms_processConfiguration'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006720'),
          path: '/iossTaxRateSetting',
          menuKey: 'yms_iossTaxRateSetting'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000952'),
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006721'),
              path: '/declarationRules',
              menuKey: 'yms_declarationRules'
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001099'),
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006722'),
              path: '/automaticDistributionRules',
              menuKey: 'yms_automaticDistributionRules'
            }
          ]
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006723'),
      icon: '&#xe68b;',
      type: 'helpCenter',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000036'),
          path: '/helpCenter',
          menuKey: 'yms_helpCenter',
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006724'),
          path: '/questionnaireInvestigation',
          menuKey: 'yms_questionnaireInvestigation',
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002091'),
              path: '/questionnaireStatistics',
              menuKey: 'yms_questionnaireStatistics',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002185'),
              path: '/addQuestionnaire',
              menuKey: 'yms_addQuestionnaire',
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006725'),
              path: '/editQuestionnaire',
              menuKey: 'yms_editQuestionnaire',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006726'),
          path: '/operatingInstructions',
          menuKey: 'yms_operatingInstructions',
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000835'),
          id: 'toSystemAnnouncement',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'yms_systemAnnouncement',
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006727'),
      icon: '&#xe691;',
      type: 'dataCenter',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002148'),
          path: '/deliveryDetails',
          menuKey: 'yms_deliveryDetails',
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006728'),
      icon: '&#xe66a;',
      type: 'activityManage',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006729'),
          path: '/siteManage',
          menuKey: 'yms_siteManage',
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006730'),
          path: '/activityManage',
          menuKey: 'yms_activityManage',
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006731'),
          path: '/editActivity',
          menuKey: 'yms_editActivity',
          menuHide: true // 菜单不展示
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006732'),
          path: '/advertisingManage',
          menuKey: 'yms_advertisingManage',
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003994'),
          path: '/rotationChart',
          menuKey: 'yms_rotationChart',
          menuHide: true // 菜单不展示
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003961'),
          path: '/vajraDistrict',
          menuKey: 'yms_vajraDistrict',
          menuHide: true // 菜单不展示
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003995'),
          path: '/topAdvertisingSpace',
          menuKey: 'yms_topAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003996'),
          path: '/sideAdvertisingSpace',
          menuKey: 'yms_sideAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003997'),
          path: '/infoAdvertisingSpace',
          menuKey: 'yms_infoAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002963'),
          path: '/subsidyRules',
          menuKey: 'yms_subsidyRules',
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006733'),
          path: '/distributorFeeAllocation',
          menuKey: 'yms_distributorFeeAllocation',
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000285'),
      path: '/messageCenter',
      icon: '&#xe8be;',
      menuKey: 'yms_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      type: 'messageCenter',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006734'),
          path: '/messageMailboxes',
          menuKey: 'yms_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006735'),
      icon: '&#xe693;',
      path: '/employeeList',
      menuKey: 'yms_employeeList',
      type: 'employeeList',
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006736'),
      icon: '&#xe664;',
      type: 'importTask',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006737'),
          path: '/importTask',
          menuKey: 'yms_importTask'
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006738'),
          path: '/exportTask',
          menuKey: 'yms_exportTask'
        }
      ]
    }
  ]
};
