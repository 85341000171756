<template>
  <div>
    <template v-for="item in subMenuDate">
      <Submenu v-if="item.children" :name="item.id">
        <template #title>
          <div class="flex align-items-center" :class="handleStyles(item)">
            <i class="iconfont" v-if="item.icon" v-html="item.icon"></i>
            <span class="white-space-nowrap text_styles" :title="item.name" v-html="item.name"></span>
          </div>
        </template>
        <template v-for="subMenu in item.children">
          <div v-if="subMenu.children">
            <sub-menu :subMenuDate="[subMenu]"></sub-menu>
          </div>
          <div v-else>
            <MenuItem v-if="subMenu.path" :name="subMenu.id" :to="subMenu.path" :target="subMenu.target ? subMenu.target : '_self'">
              <div class="flex align-items-center">
                <i class="iconfont" v-if="subMenu.icon" v-html="item.icon"></i>
                <span :title="subMenu.name" v-html="subMenu.name" class="white-space-nowrap text_styles"></span>
                <Badge v-if="subMenu.quantity" class="ml6" :count="subMenu.quantity"></Badge>
              </div>
            </MenuItem>
            <div v-else class="white-space-nowrap ivu-menu-item reset-listing-menu">
              <span v-if="subMenu.externalLink" @click="handleExternalLink(subMenu)">
                <span :title="subMenu.name" v-html="subMenu.name"></span>
              </span>
              <span v-else :href="subMenu.href" :target="subMenu.target ? subMenu.target : '_blank'">
                <span v-html="subMenu.name" :title="subMenu.name"></span>
              </span>
            </div>
          </div>
        </template>
      </Submenu>
      <template v-else>
        <MenuItem :name="item.id" v-if="item.path" :to="item.path" :target="item.target ? item.target : '_self'">
          <div class="flex align-items-center">
            <i class="iconfont" v-if="item.icon" v-html="item.icon"></i>
            <span v-html="item.name" :title="item.name" class="white-space-nowrap text_styles"></span>
            <Badge v-if="item.quantity" class="ml6" :count="item.quantity"></Badge>
          </div>
        </MenuItem>
        <div v-else class="white-space-nowrap link_style">
          <a v-if="item.externalLink" @click="handleExternalLink(item)">
            <i class="iconfont" v-if="item.icon" v-html="item.icon"></i>
            <span v-html="item.name" :title="item.name"></span>
          </a>
          <a v-else :href="item.href" :target="item.target ? item.target : '_blank'">
            <i class="iconfont" v-if="item.icon" v-html="item.icon"></i>
            <span v-html="item.name" :title="item.name"></span>
          </a>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import api from '@/api/';
import {commonSessionStorage, getCommonTicket} from "@/utils/common";

export default {
  name: 'subMenu',
  props: {
    subMenuDate: {
      type: Array
    }
  },
  computed: {
    isSaaS() {
      let value = commonSessionStorage.getItem('openClothingCategories') || this.$store.state.openClothingCategories
      return !value;
    },
    merchantId() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.merchant.merchantId
      } else {
        return '';
      }
    },
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  methods: {
    // 处理跳转到外部系统的链接地址
    handleExternalLink(item) {
      let menuKey = item.menuKey;
      let externalLink = '';
      let erpConfig = this.$store.state.erpConfig;
      let merchant = commonSessionStorage.getItem('erpConfig') ? commonSessionStorage.getItem('erpConfig').merchant : erpConfig.merchant;
      switch (menuKey) {
        // 分销商系统--上架工具
        case 'distributor_shelfTools':
          let url = '';
          if (this.isSaaS) {
            url = merchant.listingDomain;
          } else if (merchant.listingStatus === '1') {
            url = merchant.listingDomain;
          } else {
            url = merchant.ymsListingDomain;
          }
          if (url) {
            let path = url.includes('http') ? url : 'https://' + url;
            externalLink = path + '/j_security_check?ticket=';
          }
          break;
        // 运营系统--系统公告
        case 'yms_systemAnnouncement':
          let links = erpConfig.mtBaseUrl.includes('http') ? erpConfig.mtBaseUrl : 'https://' + erpConfig.mtBaseUrl;
          externalLink = links + '/j_security_check?ticket=';
          break;
      }
      if (externalLink) {
        let query = {
          userId: this.userInfo.userId,
          merchantId: this.merchantId
        }
        getCommonTicket(query).then((ticket) => {
          if (ticket) {
            window.open(externalLink + ticket);
          }
        });
      } else {
        console.log('=====获取跳转链接失败========', externalLink)
      }
    },
    // 处理菜单项待处理标记
    handleStyles(item) {
      let className = '';
      if (item) {
        if (item.children && item.children.length > 0) {
          let talg = item.children.some((item) => {
            return item.quantity > 0
          });
          if (talg) {
            className = 'set_title_styles';
          }
        }
      }
      return className;
    }
  }
};
</script>
<style>
.reset-listing-menu .ivu-menu-vertical, .reset-listing-menu .ivu-menu-vertical {
  color: rgb(81, 90, 110);
}
</style>
<style lang="less" scoped>
.white-space-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text_styles {
  max-width: 135px;
  display: inline-block;
}

.iconfont {
  margin-right: 6px;
}

.link_style {
  padding: 14px 24px;

  a {
    color: #515a6e;

    &:hover {
      color: #FD5425;
    }
  }
}

/deep/ .ivu-badge .ivu-badge-count {
  top: -1px;
  line-height: 13px;
}

.set_title_styles {
  position: relative;

  &:after {
    content: ' ';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    background-color: #ED4014;
    position: absolute;
    right: 25px;
    top: 8px;
    z-index: 10;
  }
}
</style>
