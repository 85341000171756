
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 分销商后台管理系统--菜单
 * @author jing
 * @date 2021/1/19 9:49
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 * @attribute distributorType 分销商类型(ALIGJ: 阿里国际分销、YMS：云卖分销商)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006758'),
      icon: '&#xe6b2;',
      path: '/distributionMarket',
      menuKey: 'distributor_distributionMarket',
      distributorType: ['ALIGJ']
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005586'),
      icon: '&#xe618;',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000826'),
          path: '/myInventory',
          menuKey: 'distributor_myInventory',
          distributorType: ['YMS']
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006759'),
          path: '/commodityList',
          menuKey: 'distributor_commodityList',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006760'),
          path: '/generateSalesRecords',
          menuKey: 'distributor_generateSalesRecords',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000008'),
          type: 'toListing',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在角色管理中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'distributor_shelfTools',
          distributorType: ['YMS', 'ALIGJ']
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006761'),
      icon: '&#xe6fe;',
      path: '/automaticRules',
      menuKey: 'distributor_automaticRules',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006762'),
      icon: '&#xe604;',
      path: '/platformStoreList',
      menuKey: 'distributor_platformStoreList',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000078'),
      icon: '&#xe63a;',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006763'),
          path: '/collectionSupplier',
          menuKey: 'distributor_collectionSupplier',
          distributorType: ['YMS']
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001099'),
      icon: '&#xe668;',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004483'),
          path: '/platformOrder',
          menuKey: 'distributor_platformOrder',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006764'),
              path: '/platformDetails',
              menuKey: 'distributor_platformDetails',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006765'),
              path: '/addTemuOrder',
              menuKey: 'distributor_addTemuOrder',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001384')}`,
          path: '/myOrder',
          menuKey: 'distributor_myOrder',
          distributorType: ['YMS'],
          children: [
            {
              name: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004923')}`,
              path: '/myOrderDetails',
              menuKey: 'distributor_myOrderDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004945'),
              path: '/addCloudSalesOrder',
              menuKey: 'distributor_addCloudSalesOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004484'),
          path: '/distributorStockList',
          menuKey: 'distributor_distributorStockList',
          distributorType: ['YMS'],
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001426'),
              path: '/createBackupOrder',
              menuKey: 'distributor_createBackupOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006741'),
              path: '/distributorStockListDetails',
              menuKey: 'distributor_distributorStockListDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001100'),
      icon: '&#xe645;',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002151'),
          path: '/rechargeRecord',
          menuKey: 'distributor_rechargeRecord',
          distributorType: ['YMS'],
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002791'),
          path: '/financialJournal',
          menuKey: 'distributor_financialJournal',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006709'),
      icon: '&#xe68e;',
      children: [
        {
          name: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002961')}`,
          path: '/logisticsSettings',
          menuKey: 'distributor_logisticsSettings',
          distributorType: ['YMS'],
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001137'),
          path: '/ownLogistics',
          menuKey: 'distributor_ownLogistics',
          distributorType: ['YMS'],
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006711'),
          path: '/smartRule',
          menuKey: 'distributor_smartRule',
          distributorType: ['YMS'],
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006766'),
      icon: '&#xe61b;',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006767'),
          path: '/roleManage',
          menuKey: 'distributor_roleManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006768'),
          path: '/userManage',
          menuKey: 'distributor_userManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000285'),
      path: '/newsList',
      menuKey: 'distributor_newsList',
      distributorType: ['YMS', 'ALIGJ'],
      menuHide: true,
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006769'),
          path: '/newsListDetails',
          menuKey: 'distributor_newsListDetails',
          distributorType: ['YMS', 'ALIGJ'],
          menuHide: true
        }
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006770'),
      icon: '&#xe627;',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000604'),
          path: '/basicInformation',
          menuKey: 'distributor_basicInformation',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006771'),
          path: '/securityInformation',
          menuKey: 'distributor_securityInformation',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004444'),
              path: '/modifyMobile',
              menuKey: 'distributor_modifyMobile',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004446'),
              path: '/modifyMailbox',
              menuKey: 'distributor_modifyMailbox',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004688'),
              path: '/modifyPassword',
              menuKey: 'distributor_modifyPassword',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004387'),
          path: '/iossManageList',
          menuKey: 'distributor_iossManageList',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006736'),
      icon: '&#xe6a9;',
      type: 'importTask',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006738'),
          path: '/exportTask',
          menuKey: 'distributor_exportTask',
          distributorType: ['YMS', 'ALIGJ'],
        }
      ]
    },
    {
      name: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006757')}`,
      type: 'ymsShopping',
      children: [
        {
          name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1006772'),
          menuKey: 'shop_shopList',
          distributorType: ['YMS'],
        }
      ]
    }
  ]
};
