<template>
  <div class="layout" :class="lang" style="height: 100%">
    <Layout>
      <vHeader @collapsedSider="collapsedSider" :isCollapsed="isCollapsed" @updateMenu="updateMenu"></vHeader>
      <Layout class="main_container">
        <Sider ref="sider_box" hide-trigger collapsible :collapsed-width="0" :width="240" class="sider_container" v-model="isCollapsed">
          <v-left ref="leftComponent" :isCollapsed="isCollapsed" :key="keyTalg"></v-left>
        </Sider>
        <Layout class="content_container">
          <breadcrumb ref="breadcrumbBox"></breadcrumb>
          <!-- 如果当前打开页面的路由中 keepAlive: true （开启了缓存时） -->
          <keep-alive max="1">
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <!-- 如果当前打开页面的路由中 没有 或者为 keepAlive: false （关闭缓存时[默认就是false]） -->
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </Layout>
      </Layout>
    </Layout>
    <!--系统公告弹窗-->
    <viewAnnouncementModal></viewAnnouncementModal>
    <!--消息通知弹窗-->
    <messageNotificationModal></messageNotificationModal>
    <!--商品驳回通知弹窗-->
    <productRejectionNoticeModal v-if="system === 'supplier'"></productRejectionNoticeModal>
    <!--返回顶部按钮-->
    <BackTop :bottom="60"></BackTop>
  </div>
</template>
<style lang="less" scoped>

</style>
<script>
import vHeader from './header';
import vLeft from './left';
import breadcrumb from './menu/breadcrumb';
import Mixin from '@/components/mixin/common_mixin';
import viewAnnouncementModal from "@/components/common/viewAnnouncementModal.vue";
import messageNotificationModal from "@/components/common/messageNotificationModal";
import {addLangStyle} from '@/utils/common';
import productRejectionNoticeModal from "@/components/common/productRejectionNoticeModal";

export default {
  mixins: [Mixin],
  components: {
    productRejectionNoticeModal,
    vHeader,
    vLeft,
    breadcrumb,
    viewAnnouncementModal,
    messageNotificationModal
  },
  data() {
    return {
      isCollapsed: false,
      keyTalg: new Date().getTime(),
      lang: '',
    };
  },
  computed: {},
  methods: {
    collapsedSider(isCollapsed) {
      this.isCollapsed = isCollapsed;
    },
    updateMenu(key) {
      this.keyTalg = key;
    }
  },
  watch: {
    $route(to, from) {
      this.getPageSizeStorage();
    }
  },
  created() {
    this.$webSocket.initWebSocket();
    if (localStorage.getItem('lang')) {
      this.lang = localStorage.getItem('lang');
      addLangStyle();
    }
  }
};
</script>
<style>

</style>
