<template>
  <Modal v-model="returnedReasonModal" :title="$t('key1000436')" width="450" :transfer="false" :mask-closable="false"
    @on-visible-change="returnedReasonChange">
    <Form ref="returnedReasonForm" :model="returnedReasonForm" :rules="ruleValidate" :label-width="80" @submit.native.prevent>
      <Row style="width: 90%;">
        <h2 class="mb12">{{ $t('key1000439') }}</h2>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1000437')" prop="conent">
            <Input
              type="textarea"
              :placeholder="$t('key1000438')"
              :autosize="{ minRows: 3, maxRows: 5}"
              v-model.trim="returnedReasonForm.conent">
            </Input>
          </Form-item>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="returnedReasonModal = false">{{ $t('key1000097') }}</Button>
      <Button type="primary" @click="returnedReasonBtn">{{ $t('key1000436') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";

export default {
  name: "returnedReasonModal",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      returnedReasonModal: false,
      returnedReasonForm: {
        conent: null
      },
      ruleValidate: {
        conent: [
          {required: true, message: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000440'), trigger: 'blur'}
        ],
      },
    }
  },
  created() {

  },
  methods: {
    // 监听退回弹窗
    returnedReasonChange(value) {
      if (!value) {
        this.returnedReasonForm.conent = '';
        this.$nextTick(() => {
          this.$refs['returnedReasonForm'].resetFields();
        })
      }
    },
    // 退回
    returnedReasonBtn() {
      let v = this;
      v.$refs['returnedReasonForm'].validate((valid) => {
        if (valid) {
          v.$emit('returnedReasonBtn', this.returnedReasonForm.conent)
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>

</style>